var site = site || {};

site.translations = site.translations || {};
site.translations.elc_general = site.translations.elc_general || {};
site.direction = site.direction || {};

(function ($, site) {
  Drupal.behaviors.carouselFormatterV1 = {
    $formatter: $(),
    $carousel: $(),
    attached: false,
    attach: function (context) {
      var self = this;
      var isRTL = site?.direction?.isRTL || false;

      if (self.attached) {
        return;
      }
      self.attached = true;
      self.$formatter = $('.js-carousel-formatter-v1', context);

      self.$formatter.each(function () {
        var $thisFormatter = $(this);

        self.$carousel = $thisFormatter.find('.js-carousel');
        var $hpHeroBlockWrapper = $thisFormatter.closest('.js-hp-hero-block');
        var carouselDataset = self.$carousel.data() || null;
        var slickSlide = '.js-carousel__slide';
        var $arrowsDiv = $thisFormatter.find('.js-carousel-controls');
        var $dotsDiv = $thisFormatter.find('.js-carousel-dots');
        var $slickSlide = $thisFormatter.find(slickSlide);
        var nextLabelDefault =
          site.translations.elc_general && site.translations.elc_general.next
            ? site.translations.elc_general.next
            : 'next';
        var previousLabelDefault =
          site.translations.elc_general && site.translations.elc_general.previous
            ? site.translations.elc_general.previous
            : 'previous';
        var previousLabel = carouselDataset.previousLabel || previousLabelDefault;
        var arrowIcon = carouselDataset.arrowIcon || 'caret-thin';
        var arrowIconName = 'icon--dropdown';
        var nextLabel = carouselDataset.nextLabel || nextLabelDefault;
        var disableWidthSlides = carouselDataset.disableWidthSlides || false;
        var prevArrow =
          '<button class="slick-prev-button slick-prev slick-custom--' +
          arrowIcon +
          '"><svg role="img" aria-label="' +
          previousLabel +
          '" class="icon icon--' +
          arrowIcon +
          '--left"><use xlink:href="#' +
          arrowIconName +
          '"></use></svg></button>';
        var nextArrow =
          '<button class="slick-next-button slick-next slick-custom--' +
          arrowIcon +
          '"><svg role="img" aria-label="' +
          nextLabel +
          '" class="icon icon--' +
          arrowIcon +
          '--right"><use xlink:href="#' +
          arrowIconName +
          '"></use></svg></button>';
        var autoplayData = carouselDataset.slidesAutoplay;
        var autoplay = autoplayData || false;
        var hoverTrigger = carouselDataset.slidesHoverTrigger ? true : false;
        var slidesToShow = carouselDataset.slidesToShow || 1;
        var slidesToScroll = carouselDataset.slidesToScroll || 1;
        var defaultslideDisplayInterval = 3000;
        var speedStandard = 300;
        var speed = carouselDataset.slidesSpeed || speedStandard;
        var slideDisplayInterval =
          carouselDataset.slideDisplayInterval || defaultslideDisplayInterval;
        var $textSliderSelect = $hpHeroBlockWrapper.find('.js-carousel.banner-content');
        var $textSliders = $textSliderSelect.find(slickSlide);
        var $carouselParentBanner = $hpHeroBlockWrapper.find('.js-carousel.banner-bg');
        var fade = carouselDataset.slidesFade ? true : false;
        var asNavFor = carouselDataset.asNavFor || false;
        var focusOnSelect = carouselDataset.asNavFor ? true : false;
        var carouselPosition = carouselDataset.blockPosition || false;
        var pcDotsArrowsArgs = self.checkDotsAndArrows(carouselDataset.dotsArrowsPc);
        var mobileDotsArrowsArgs = self.checkDotsAndArrows(carouselDataset.dotsArrowsMobile);
        var settingsPlain = {
          rtl: isRTL,
          appendArrows: $arrowsDiv,
          slide: slickSlide,
          fade: fade,
          speed: speed,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          autoplay: autoplay,
          autoplaySpeed: slideDisplayInterval,
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows,
          asNavFor: asNavFor,
          focusOnSelect: focusOnSelect,
          pauseOnHover: true,
          pauseOnFocus: true,
          waitForAnimate: false,
          cssEase: 'cubic-bezier(.66, 0, .33, 1)'
        };
        var settingsResponsive = {
          rtl: isRTL,
          appendArrows: $arrowsDiv,
          slide: slickSlide,
          fade: fade,
          speed: speed,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          autoplay: autoplay,
          autoplaySpeed: slideDisplayInterval,
          hoverTrigger: hoverTrigger,
          cssEase: 'cubic-bezier(.66, 0, .33, 1)',
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows,
          asNavFor: asNavFor,
          focusOnSelect: focusOnSelect,
          appendDots: $dotsDiv,
          prevArrow: prevArrow,
          nextArrow: nextArrow,
          variableWidth: disableWidthSlides,
          pauseOnHover: true,
          pauseOnFocus: true,
          waitForAnimate: false,
          onInit: function () {
            if (asNavFor) {
              $slickSlide.not(':first').removeClass('slick-active');
            }
          },
          onSetPosition: function (slick) {
            if (disableWidthSlides) {
              slick.$slideTrack.css('height', 'auto');
            }
          },
          onBeforeChange: function (slick, currentSlide, nextSlide) {
            var $carouselParent = self.$carousel.closest('.js-carousel-content');
            if ($carouselParent.length > 0) {
              if (nextSlide === 0) {
                $carouselParent.attr('data-slide-tab-active', 'first');
              } else if (nextSlide === slick.slideCount - 1) {
                $carouselParent.attr('data-slide-tab-active', 'last');
              } else {
                $carouselParent.attr('data-slide-tab-active', 'default');
              }
              if (autoplay === true) {
                $textSliders.removeClass('slick-active');
                $textSliders.eq(nextSlide).addClass('slick-active');
              }
            }
            $textSliders.once().on('click', function () {
              var $currentSlide = $(this);
              var currentIndex = $currentSlide.index();

              $textSliders.removeClass('slick-active');
              $currentSlide.addClass('slick-active');
              $carouselParentBanner.slickGoTo(currentIndex);
            });
          },
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: mobileDotsArrowsArgs.dots,
                arrows: mobileDotsArrowsArgs.arrows,
                slidesToShow: 1,
                slidesToScroll: 1,
                pauseOnHover: true,
                pauseOnFocus: true,
                variableWidth: false
              }
            }
          ]
        };
        var useResponsive = slidesToShow > 3 ? true : false;
        if (useResponsive === true) {
          self.$carousel.not('.slick-initialized').slick(settingsPlain);
        } else {
          self.$carousel.not('.slick-initialized').slick(settingsResponsive);
        }

        //Trigger carousel on hover
        if (hoverTrigger === true && $hpHeroBlockWrapper.length > 0) {
          $textSliderSelect.on(
            'mouseenter',
            '.slick-slide',
            _.debounce(function () {
              var $currSlide = $(this);
              var currIndex = $currSlide.index();

              $carouselParentBanner.slickGoTo(currIndex);
              $textSliders.removeClass('slick-active');
              $currSlide.addClass('slick-active');
              $carouselParentBanner.slickPause();
            }, speedStandard)
          );
          if (autoplayData === true) {
            $textSliderSelect.on('mouseleave', function () {
              $carouselParentBanner.slickPlay();
            });
          }
        }
      });
    },
    checkDotsAndArrows: function (dotsAndArrows) {
      var dotsArrowsArgs = {};

      switch (dotsAndArrows) {
        case 'arrows':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = true;
          break;
        case 'dots':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = false;
          break;
        case 'both':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = true;
          break;
        case 'none':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = false;
          break;
      }

      return dotsArrowsArgs;
    }
  };
})(jQuery, window.site || {});
